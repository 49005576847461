


























































/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { mapState } from 'vuex';
import PageMain from '@part/layout/PageMain.vue';
import InfoToast from '@part/elements/InfoToast.vue';
import lang from '@lang/length.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
        InfoToast
    },
    props: [],
    data() {
        return {
        };
    },
    computed: {
        lang(){
            return lang[this.activeLang()];
        },
        ...mapState(['sessionData']),
    },
    methods: {
    },
    mounted() {
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Dev extends Vue {}
