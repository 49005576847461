export default {
    en: {

        title1: 'STYLE',
        question1: 'Would you like low, regular or long socks?',
        info1: 'Check out the sample socks to see the difference',

        title2: 'MODEL',
        question2: 'Would you like a regular model or an extra-wide one?',
        info2: 'Check out the sample scarves to see the difference',

        low: 'LOW',
        regular: 'REGULAR',
        high: 'KNEE-HIGHS',
        wide: 'EXTRA WIDE'
    },
    nl: {
        title1: 'LENGTE SOK',
        question1: 'Ga je voor lage, normale of hoge sokken?',
        info1: 'Bekijk de voorbeelden om het verschil te zien',

        title2: 'MODEL',
        question2: 'Ga je voor een normale sjaal of een extra breed model?',
        info2: 'Bekijk de voorbeelden om het verschil te zien',

        low: 'LAAG',
        regular: 'NORMAAL',
        high: 'HOOG',
        wide: 'EXTRA BREED'
    }
};